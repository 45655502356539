/*
    main.js -- Manager main
 */

//  Import the manager class, Vuetify helper and Manager CSS
import {createManager, vuetifyProps} from 'manager'
import 'manager/manager.css'

//  Import the GraphWidget and AWS Cognito authenticator from the managers extras
import {Cognito, GraphWidget} from 'manager/extras'

//  Import core Vue, VueRouter and Vuetity components
import {createApp} from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

//  Import App configuration 
import Schema from '../../../../config/schema.json5'
import Display from '../../config/display.json5'
import Build from '../../config/build.json5'
import Ioto from '../../config/ioto.json5'

//  Backend configuration provided by the cloud service
import Connect from '../Connect.js'

//  Import App VueJS components
import Main from './Main.vue'
import Custom from './Custom.vue'

//  Import additional MaterialDesign icons
import {mdiFormatListBulletedSquare} from '@mdi/js'
const Icons = {
    events: mdiFormatListBulletedSquare,
}

async function main() {
    let vue = createApp(Main)
    let router = createRouter({
        history: createWebHashHistory(),
        routes: [],
    })
    let vuetify = createVuetify(
        vuetifyProps({
            components: components,
            directives: directives,
            icons: Icons,
            themes: Display.theme.themes,
        })
    )
    vue.use(vuetify)

    await createManager({
        components: { Custom },             //  App VueJS components
        display: Display,                   //  UI Display definition
        schema: Schema,                     //  Database schema 
        widgets: {graph: GraphWidget},      //  Dashboard widgets
        cognito: Cognito,                   //  AWS Cognito authenticator
        router,                             //  VueJS router
        vue,                                //  Vue instance
        vuetify,                            //  Vuetify instance
    }, {
        api: Connect.api,                   //  Backend cloud service API
        cognito: Connect.cognito,           //  Cognito config
        build: Build.build,                 //  App build number
        profile: Ioto.profile,              //  Execution profile (prod | dev)
        name: Display.name,                 //  App name
        title: Connect.title || Display.title,  //  App display title
        version: Display.version,           //  App version (x.y.z)
    })

    /*
        Start VueJS and authenticate the user
     */
    vue.use(router)
    vue.mount('#main')
    router.push({path: '/auth/login'})
}

main()
